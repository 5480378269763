import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Lenis from '@studio-freight/lenis';

document.addEventListener('DOMContentLoaded', onLoad);

function onLoad(){
    gsap.registerPlugin(ScrollTrigger);
	document.querySelector('.loader').classList.remove('loader_show')
    initCursor();
}

function initCursor(){
    let cx, cy, mouseX, mouseY, posX, posY;
    const body = document.querySelector('body');

	cx = window.innerWidth / 2;
	cy = window.innerHeight / 2;

	body.addEventListener('mousemove', e => {
		mouseCoords(e);
		cursor.classList.remove('hidden');
		follower.classList.remove('hidden');

	})

	const cursor   = document.querySelector('.cursor');
	const follower = document.querySelector('.cursor-aura');

	mouseX = 0, mouseY = 0, posX = 0, posY = 0

	function mouseCoords(e) {
		mouseX = e.clientX;
		mouseY = e.clientY;
	}

	gsap.to({}, .01, {

		repeat: -1,

		onRepeat: () => {

			posX += (mouseX - posX) / 5
			posY += (mouseY - posY) / 5

			gsap.set(cursor, {
				css: {
					left: mouseX,
					top: mouseY
				}
			})

			gsap.set(follower, {
				css: {
					left: posX - 24,
					top: posY - 24
				}
			})

		}

	})

    document.querySelectorAll('a, .link').forEach(x => {
        x.addEventListener('mouseover', () => {
			cursor.classList.add('active')
			follower.classList.add('active')
		})

		x.addEventListener('mouseout', () => {
			cursor.classList.remove('active')
			follower.classList.remove('active')
		})
    })

	body.addEventListener('mouseout', () => {
		cursor.classList.add('hidden')
		follower.classList.add('hidden')
	})
}


